<template>
  <v-container fluid>
    <p class="text-h5 text-left">일정 관리</p>
    <v-divider></v-divider>
    <v-row class="search-box pa-0 mx-0 mt-2">
      <v-col class="pa-0">
        <v-sheet>
          <v-toolbar flat>
            <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
            >
              Today
            </v-btn>
            <v-spacer/>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-spacer/>
            <v-menu
                bottom
                right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row class="mx-0">
      <v-col cols="12" class="px-0">
        <v-sheet width="100%" height="650">
          <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              :event-more="false"
              @change="getEvents"
              @click:date="openEvent"
              @click:event="openEvent"
              @click:more="openMoreEvents"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <event-dialog v-if="dialog" :item="selectedItem" @close="closeDialog"></event-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import EventDialog from './EventDialog'
import moment from 'moment'
export default {
  name: 'EventsPage',
  components: {EventDialog},
  data() {
    return {
      tab: null,
      type: 'month',
      mode: 'stack',
      locale: 'ko',
      weekday: [0, 1, 2, 3, 4, 5, 6],
      focus: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
      },
      dialog: false,
      selectedItem: null,
      start: null,
      end: null,
    }
  },
  methods: {
    search() {
      const param = {
        startDate: this.start,
        endDate: this.end
      }

      this.$http.get('/api/events', {params: param})
      .then(({ data }) => {
        this.events = data
      })
    },
    setToday () {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    getEvents ({ start, end }) {
      this.start = start.date
      this.end = end.date
      this.search()
    },
    getEventColor (event) {
      return event.color || 'grey'
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    openEvent({ date, time, event }) {
      if (event) {
        this.selectedItem = event
        this.selectedItem.startDt = moment(event.start).format('YYYY-MM-DD')
        this.selectedItem.startTm = moment(event.start).format('HH:mm')
        this.selectedItem.endDt = moment(event.end).format('YYYY-MM-DD')
        this.selectedItem.endTm = moment(event.end).format('HH:mm')
      } else {
        this.selectedItem = {
          startDt: date,
          startTm: time || '00:00'
        }
      }
      this.dialog = true
    },
    closeDialog(result) {
      this.dialog = false
      if (result !== 'dismiss') {
        this.search()
      }
    }
  },
}
</script>

<style>
.v-calendar-weekly {
  display: table !important;
  table-layout: fixed !important;
}
.v-calendar-weekly__week {
  //height: auto !important;
  //min-height: 100px !important;
  //display: table-row !important;
}
.v-calendar-weekly__day {
  display: table-cell !important;
  min-height: 120px !important;
  width: calc(100% / 7);
}
</style>
