<template>
  <v-card>
    <v-toolbar flat dark color="primary">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>일정 {{ editItem.boardId ? '수정' : '등록' }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12">
          <v-text-field
            v-model="editItem.title"
            label="일정"
            hide-details
            class="txt-events"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="editItem.remark"
            label="내용"
            hide-details
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-btn-toggle v-model="color">
            <v-btn v-for="(c, index) in colors" :key="index">
              <v-icon :color="colors[index]">mdi-rectangle</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-menu
              ref="date1"
              v-model="date1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="editItem.startDt"
                  label="시작일"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="editItem.startDt"
                no-title
                scrollable
                @input="date1 = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-menu
              ref="time1"
              v-model="time1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="editItem.startTm"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="editItem.startTm"
                  label="시작시간"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="time1"
                v-model="editItem.startTm"
                full-width
                @click:minute="$refs.time1.save(editItem.startTm)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-menu
              ref="date2"
              v-model="date2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="editItem.endDt"
                  label="종료일"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="editItem.endDt"
                no-title
                scrollable
                @input="date2 = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-menu
              ref="time2"
              v-model="time2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="editItem.endTm"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="editItem.endTm"
                  label="종료시간"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="time2"
                v-model="editItem.endTm"
                full-width
                @click:minute="$refs.time2.save(editItem.endTm)"
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="editItem.seq" color="error" @click="remove">삭제</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="default" @click="close('dismiss')">닫기</v-btn>
      <v-btn color="primary" @click="save">저장</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'EventDialog',
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      date1: null,
      date2: null,
      time1: null,
      time2: null,
      editItem: {
        seq: null,
        title: null,
        subTitle: null,
        remark: null,
        startDate: null,
        endDate: null,
        startDt: null,
        startTm: null,
        endDt: null,
        endTm: null,
        color: null,
      },
      color: 0,
      colors: ['grey', 'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', ],
    }
  },
  mounted() {
    this.editItem = Object.assign({}, this.item)
    if (this.editItem.color) {
      this.color = this.colors.indexOf(this.editItem.color)
    }
  },
  methods: {
    save() {
      if (!this.validate()) return

      this.editItem.startDate = this.editItem.startDt + ' ' + (this.editItem.startTm || '00:00')
      this.editItem.endDate = this.editItem.endDt + ' ' + (this.editItem.endTm || '00:00')
      this.editItem.color = this.color > -1 ? this.colors[this.color] : this.colors[0]
      this.$http.post('/api/events', this.editItem)
        .then(({ data }) => {
          if (data === 'success') {
            this.close('ok')
          } else {
            alert('저장에 실패하였습니다.')
          }
        })
        .catch(() => {
          alert('오류가 발생하였습니다.')
        })
    },
    remove() {
      if (confirm('해당 일정을 삭제하시겠습니까?')) {
        this.$http.post('/api/events/delete', this.editItem)
        .then(({ data }) => {
          if (data === 'success') {
            this.close('ok')
          } else {
            alert('삭제에 실패하였습니다.')
          }
        })
        .catch(() => {
          alert('오류가 발생하였습니다.')
        })
      }
    },
    validate() {
      if (!this.editItem.title) {
        alert('일정을 입력해 주세요.')
        return false
      }

      if (!this.editItem.startDt) {
        alert('시작일을 입력해 주세요.')
        return false
      }

      if (!this.editItem.endDt) {
        alert('종료일을 입력해 주세요.')
        return false
      }

      return true
    },
    close(result) {
      this.$emit('close', result)
    },
    cancel() {
      this.$emit('close', 'dismiss')
    },
  }
}
</script>

<style>
.txt-events input {
  text-align: left !important;
}
</style>
