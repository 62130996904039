var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("일정 "+_vm._s(_vm.editItem.boardId ? '수정' : '등록'))])],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"txt-events",attrs:{"label":"일정","hide-details":""},model:{value:(_vm.editItem.title),callback:function ($$v) {_vm.$set(_vm.editItem, "title", $$v)},expression:"editItem.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"내용","hide-details":""},model:{value:(_vm.editItem.remark),callback:function ($$v) {_vm.$set(_vm.editItem, "remark", $$v)},expression:"editItem.remark"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn-toggle',{model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},_vm._l((_vm.colors),function(c,index){return _c('v-btn',{key:index},[_c('v-icon',{attrs:{"color":_vm.colors[index]}},[_vm._v("mdi-rectangle")])],1)}),1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"date1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"시작일","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editItem.startDt),callback:function ($$v) {_vm.$set(_vm.editItem, "startDt", $$v)},expression:"editItem.startDt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.date1 = false}},model:{value:(_vm.editItem.startDt),callback:function ($$v) {_vm.$set(_vm.editItem, "startDt", $$v)},expression:"editItem.startDt"}})],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"time1",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.editItem.startTm,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editItem, "startTm", $event)},"update:return-value":function($event){return _vm.$set(_vm.editItem, "startTm", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"시작시간","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.editItem.startTm),callback:function ($$v) {_vm.$set(_vm.editItem, "startTm", $$v)},expression:"editItem.startTm"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.time1),callback:function ($$v) {_vm.time1=$$v},expression:"time1"}},[(_vm.time1)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.time1.save(_vm.editItem.startTm)}},model:{value:(_vm.editItem.startTm),callback:function ($$v) {_vm.$set(_vm.editItem, "startTm", $$v)},expression:"editItem.startTm"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"date2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"종료일","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editItem.endDt),callback:function ($$v) {_vm.$set(_vm.editItem, "endDt", $$v)},expression:"editItem.endDt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.date2 = false}},model:{value:(_vm.editItem.endDt),callback:function ($$v) {_vm.$set(_vm.editItem, "endDt", $$v)},expression:"editItem.endDt"}})],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"time2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.editItem.endTm,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editItem, "endTm", $event)},"update:return-value":function($event){return _vm.$set(_vm.editItem, "endTm", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"종료시간","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.editItem.endTm),callback:function ($$v) {_vm.$set(_vm.editItem, "endTm", $$v)},expression:"editItem.endTm"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.time2),callback:function ($$v) {_vm.time2=$$v},expression:"time2"}},[(_vm.time2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.time2.save(_vm.editItem.endTm)}},model:{value:(_vm.editItem.endTm),callback:function ($$v) {_vm.$set(_vm.editItem, "endTm", $$v)},expression:"editItem.endTm"}}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[(_vm.editItem.seq)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.remove}},[_vm._v("삭제")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){return _vm.close('dismiss')}}},[_vm._v("닫기")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("저장")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }